import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Image from "gatsby-image";
import React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import CarouselControl from "../components/carouselControl";
import Label from "../components/label";
import RelatedArticles from "../components/relatedArticles";
import { Contacts, References, Teaser } from "../components/sidebar";
import { carouselBreakpoints } from "../helper/carousel";
import { formatDate } from "../helper/date";
import { getFilteredSearchLink } from "../helper/link";
import { dtrOptions } from "../helper/richText";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const ArticleDetail = ({ data: { articleGeneral }, pageContext }) => {
  const { settings } = pageContext;
  const article = articleGeneral.articleDetail;
  const siteSettings = settings;

  const [showLightbox, setShowLightbox] = React.useState(false);
  const [imageToShow, setImageToShow] = React.useState(0);

  const showImage = imageIndex => {
    setImageToShow(imageIndex);
    setShowLightbox(true);
  };

  const closeLightbox = () => {
    setShowLightbox(false);
  };

  const responsive = {
    xl: {
      breakpoint: carouselBreakpoints.xl,
      items: 2,
    },
    l: {
      breakpoint: carouselBreakpoints.l,
      items: 2,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      items: 2,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 2,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 1,
    },
  };

  if (article) {
    article.organization = article.organization || [];
  }

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${article && article.articleTitle}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
        ogImage={article?.articleImages?.[0]?.image?.fluid.src}
      />
      {article && article.headerImage && (
        <>
          <BackgroundImage
            Tag="section"
            className={`pageHeader`}
            fluid={article.headerImage.image.fluid}
          ></BackgroundImage>
          <span class="image-caption">{article.headerImage.caption}</span>
        </>
      )}
      <Container fluid className="articleDetail">
        <Row>
          <Col lg={4} className="order-2">
            {article && (
              <>
                {article.authorOfPublication && (
                  <Contacts
                    authors={article.authorOfPublication.map(author => ({
                      ...author,
                      image: author.photo && author.photo.fixed,
                      bio: author.biography && author.biography.biography,
                    }))}
                    locale={article.node_locale}
                  />
                )}
                {article.additionalInfo &&
                  article.additionalInfo.map(info => (
                    <Teaser
                      headline={info.headline}
                      content={documentToReactComponents(
                        info.body.json,
                        dtrOptions
                      )}
                      link={info.link}
                      locale={article.node_locale}
                    />
                  ))}
                {article.references && (
                  <References
                    references={article.references.map(reference => ({
                      ...reference,
                      metadescription:
                        reference.metadescription &&
                        reference.metadescription.content[0].content[0].value,
                    }))}
                    locale={article.node_locale}
                  />
                )}
              </>
            )}
          </Col>
          <Col lg={8} className="order-1">
            <h2 className="title">{article && article.articleTitle}</h2>
            <h5 className="subtitle">{article && article.articleSubtitle}</h5>
            <div className="publication-date">
              {article && formatDate(article.postDateTime, article.node_locale)}
            </div>
            <div className="labels">
              {article &&
                [...article.topic, article.focusOfWork, ...article.organization]
                  .filter(item => !!item)
                  .map(item => (
                    <Label
                      key={item.contentful_id}
                      text={item.name}
                      type="secondary"
                      className="label"
                      link={getFilteredSearchLink(
                        item.contentful_id,
                        article.node_locale
                      )}
                    />
                  ))}
            </div>
            {article && article.articleImages && article.articleImages.length && (
              <>
                <Image
                  className="article-image"
                  fluid={article.articleImages[0].image.fluid}
                ></Image>
                <span class="image-caption">
                  {article.articleImages[0].caption}
                </span>
              </>
            )}

            <div className="article-reader">
              {article &&
                article.articleReader &&
                documentToReactComponents(
                  article.articleReader.json,
                  dtrOptions
                )}
            </div>

            {article &&
              documentToReactComponents(
                article.articleContent.json,
                dtrOptions
              )}
            {article &&
              article.articleImages &&
              article.articleImages.length > 1 && (
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  infinite={true}
                  containerClass="frient-carousel article-gallery"
                  customButtonGroup={<CarouselControl />}
                  arrows={false}
                  dots={false}
                  dotListClass="frient-carousel-dots"
                >
                  {article.articleImages.slice(1).map((image, index) => (
                    <Col xs={12} onClick={() => showImage(index)}>
                      <BackgroundImage
                        className={"article-gallery-image"}
                        fluid={image.image.fluid}
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="lg"
                          className="article-gallery-image-zoom-icon"
                        />
                      </BackgroundImage>
                      <span className="image-caption">{image.caption}</span>
                    </Col>
                  ))}
                </Carousel>
              )}
          </Col>
        </Row>
      </Container>
      {articleGeneral.articleDetail && (
        <RelatedArticles article={articleGeneral} />
      )}
      {showLightbox ? (
        <Lightbox
          allowZoom={false}
          allowRotate={false}
          allowReset={false}
          startIndex={imageToShow}
          showTitle={false}
          onClose={closeLightbox}
          images={article.articleImages
            .slice(1)
            .map(image => image.image.fluid.src)}
        />
      ) : null}
    </Layout>
  );
};

export const pageQuery = graphql`
  query($contentful_id: String!, $node_locale: String!) {
    articleGeneral: contentfulArticleGeneral(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      id
      contentful_id
      __typename
      articleDetail {
        node_locale
        organization {
          contentful_id
          name
        }
        postDateTime
        region {
          contentful_id
          id
          name
        }
        typeOfArticle {
          name
        }
        articleTitle
        articleReader {
          json
        }
        articleSubtitle
        visibleTags
        headerImage {
          caption
          image {
            fluid(quality: 100) {
              srcWebp
              srcSetWebp
              srcSet
              src
              aspectRatio
            }
          }
        }
        articleImages {
          caption
          image {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              aspectRatio
            }
          }
        }
        articleContent {
          json
        }
        authorOfPublication {
          name
          email
          biography {
            biography
          }
          photo {
            fixed(width: 80, height: 80, resizingBehavior: FILL, quality: 100) {
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        focusOfWork {
          contentful_id
          name
        }
        topic {
          contentful_id
          name
        }
        references {
          title
          link
          metadescription {
            content {
              content {
                value
              }
            }
          }
        }
        additionalInfo {
          title
          headline
          body {
            json
          }
          link {
            linkText
            url
          }
        }
      }
    }
  }
`;

export default ArticleDetail;
